import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imghistory from '../../assets/History/imgHistory.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import img1962 from '../../assets/60YearHistory/img1962.jpg'
import img1989 from '../../assets/60YearHistory/img1989.jpg'
import img2009 from '../../assets/60YearHistory/img2009.jpg'
import ImageText from '../../Components/ImageText/ImageText';
import imgMark from '../../assets/mark.png'
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import ImageCard from '../../Components/ImageCard/ImageCard';
import imgtraining from '../../assets/60YearHistory/imgTraining.png';
import imgRetirement from '../../assets/60YearHistory/imgRetirement.jpg';
import imgsaddle from '../../assets/60YearHistory/imgSaddle.jpg';
import { Container, Row, Col } from 'react-bootstrap';
import ShowcaseCarousel from '../../Components/ShowcaseCarousel/ShowcaseCarousel';
import img60years1962 from '../../assets/60YearHistory/60years1962.jpg'
import img60years1969 from '../../assets/60YearHistory/60years1969.jpg'
import img60years1974 from '../../assets/60YearHistory/60years1974.jpg'
import img60years1986 from '../../assets/60YearHistory/60years1986.jpg'
import img60years1989 from '../../assets/60YearHistory/60years1989.jpg'
import img60years1995 from '../../assets/60YearHistory/60years1995.jpg'
import img60years2001 from '../../assets/60YearHistory/60years2001.jpg'
import img60years2010 from '../../assets/60YearHistory/60years2010.jpg'
import img60years2020 from '../../assets/60YearHistory/60years2020.jpg'
function SixtyYearOfHansaflex() {


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);






  const img1962history = {
    image: img1962,
    title: 'The early years 1962-1989',
    description: 'At the beginning of the 1960s, replacement hydraulic components were difficult to find in Germany. Joachim Armerding saw the potential and started assembling hydraulic hose lines at home on the green workbench in his garage. He delivered the hose lines personally to his customers. In 1969, his friend Günter Buschmann joined the fledgling firm. This laid the foundations for today’s family company. In 1986, the founders’ sons joined the company. By the end of 1988, HANSA-FLEX had grown from a small garage operation into a company operating across Germany with a total of 21 branches.'
  }

  const img1989history = {
    image: img1989,
    title: 'Strong growth and internationalisation 1989-2009 ',
    description: 'German reunification and the fall of the Iron Curtain signal two decades of rapid growth for HANSA-FLEX. The first foreign branch is founded in 1989 in Elst, the Netherlands, and is followed by numerous branches in other European countries. In 1995, Thomas Armerding and Uwe Buschmann take over the management of the company. Soon after that, the company’s first branch outside Europe is founded in Iskenderun, Turkey. Branches in North and South America, and Africa follow in 1999 and 2001 respectively. At the end of 2009, the company’s Asian head office is established in Shanghai, China, for fulfilling the demands of the market in Asia. By May 2008, there are a total of 338 HANSA-FLEX locations in 35 countries, including 182 in Germany. '
  }

  const img2009history = {
    image: img2009,
    title: 'In the age of digitalisation – from 2009 to today',
    description: 'With the advance of global digitalisation, HANSA-FLEX increases its investment in the company’s digital infrastructure and associated services. The HANSA-FLEX app, online shop and customer portal My.HANSA-FLEX provide real added value. New international subsidiaries are established in countries such as Australia, United Kingdom and India. In 2019, the global organisation and digitalisation project PHOENIX starts. In 2020, HANSA-FLEX AG is working for more than 150,000 active customers across the world.'
  }






  //  FeatureCardData

  const featuresData = [
    {
      title: "4208 employees",
      description: "We work with great passion every day for our customers",
      image: imgMark
    },
    {
      title: "449 locations",
      description: "We offer an all-round service for hydraulics in every branch – fast & individual.",
      image: imgMark
    },
    {
      title: "401 service vehicles",
      description: "The mobile Rapid Hydraulic Service is available around the clock, 365 days a year.",
      image: imgMark
    },
    {
      title: "41 countries",
      description: "Our regional branches work closely with our headquarters in Bremen",
      image: imgMark
    }
  ];


  const carouselItems = [
    {
      imgSrc: img60years1962,
      title: '1962',
      description: 'Joachim Aemending founds the company "Joachim Armeding Industriebedarf" The company operates from his garage at his house in Achim near Breman.'
    },

    {
      imgSrc: img60years1969,
      title: '1969',
      description: 'The second branch opens in Osanabrück. Freind and associate Günter Buschmann joins the company as partner shortly thereafter..'
    },

    {
      imgSrc: img60years1974,
      title: '1974',
      description: 'The company is renamed "HANSA-FLEX".The company adopts its first official logo one year later.'
    },

    {
      imgSrc: img60years1986,
      title: '1986',
      description: "Thomas Armeding and Uwe Buschmann, the founders son join the company."
    },

    {
      imgSrc: img60years1989,
      title: '1989',
      description: 'The new head office and a manually operated central warehouse are established at Am Panrepel 44 in Bremen-Mahndorf.'
    },

    {
      imgSrc: img60years1995,
      title: '1995',
      description: 'Thomas Armeding and Uwe Buschmann are appointed managing directors.'
    },

    {
      imgSrc: img60years2001,
      title: 'May 2001',
      description: 'The setting up of the Rapid Hydraulic Servive represents a real milestone in the range of HANSA-FLEX services.'
    },

    {
      imgSrc: img60years2010,
      title: 'August 2010',
      description: 'HANSA-FLEX Gmbh becomes HANSA-FLEX AG. The shares remain in the hands of the founding families, Armeding and Buschmann.Thomas Armending becomes chairman of the board of directors, with Uwe Buschmann serving as his deputy.'
    },

    {
      imgSrc: img60years2020,
      title: 'October 2020',
      description: 'Ground-breaking ceremony for the new building to extend the company headquarters in Bremen-Mahndorf. The total investment amounts to more than 22 million euro.'
    },
  ];


  return (
    <div>
      <div>
        <CenterStage
          imageSrc={imghistory}
          title={"PASSIONATE ABOUT CUSTOMER CARE SINCE 1962"}
          paragraph={"Bremen businessman Joachim Armerding founded the HANSA-FLEX company 60 years ago in the garage of his house near Bremen. His business concept was timely, his motto as simple as it was ingenious: Fulfil the wishes of your customers more rapidly, more flexibly and more fully than all your competitors and never forget the human side and enjoyment of your work. HANSA-FLEX has grown with this passion, which is as evident today as it was back then."}
        />
      </div>

      <div className='div-gray'>
        <SectionHeader
          title={"60 YEARS OF PASSION FOR PROBLEM-SOLVING"}
          subtitle={"From day one, HANSA-FLEX has been an out-and-out customer-focused company with a great passion for finding the best solution to every conceivable hydraulics challenge. We have achieved a lot in 60 years."}
        />

        <div >
          <br />
          <ImageText data={img1962history} left={false} /> <br /><br />
        </div>

        <div >
          <br />
          <ImageText data={img1989history} /> <br /><br />
        </div>

        <div >
          <br />
          <ImageText data={img2009history} left={false} /> <br /><br />
        </div>



      </div>


      <div className='div-WHITE center-all-items'>
        <SectionHeader
          title="HANSA-FLEX over the years"
          subtitle="The HANSA-FLEX story began 60 years ago in a small garage in Achim near Bremen. On a green workbench, which still stands in the company HQ in Bremen, the first hydraulic hose lines were assembled by Joachim Armerding himself. Significant milestones in our 60-year history. A complete chronicle can be found on our history page."
        />

      </div>

      <div>
        <ShowcaseCarousel carouselItems={carouselItems} isExploreButton={false} descDisplay='block' carouselType={2} />
      </div>

      <div className='div-WHITE center-all-items'>
        <SectionHeader
          title="HANSA-FLEX in figures"
          subtitle="HANSA-FLEX is one of the world's leading system suppliers for all fields of hydraulics"
        />

      </div>


      {/* FeatureCardCarousal */}

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>


      <div className='div-gray'>
        <SectionHeader
          title={"PASSION TAKES MANY FORMS"}
          subtitle={"Well-trained, ready to help, team spirited and professional – that’s how you find the employees of HANSA-FLEX in their work environment. In our series “People at HANSA-FLEX”, they show something of their lives outside work."}
        />

        <Container className="custom-container">
          <Row>
            <Col sm={12} md={6} lg={4} ><ImageCard image={imgtraining} title="Training at HANSA-FLEX – the first 60 days" desc={"At HANSA-FLEX, Warehouse Logistics Specialist and the two-year variant Warehouse Specialist are the most common training courses, with just under 50% of our trainees learning this profession."} /></Col>
            <Col sm={12} md={6} lg={4} ><ImageCard image={imgRetirement} title="60 days until retirement" desc="After 25 years at HANSA-FLEX, Falk Simon is now about to take his well-deserved retirement. He looks back over the last 60 days and reports on his time at HANSA-FLEX" /></Col>
            <Col sm={12} md={6} lg={4} ><ImageCard image={imgsaddle} title="Firmly in the saddle" desc="Whether it&apos;s show jumping, dressage, riding in the Wadden Sea, training horses or coaching young riders: for Marie Abel equestrian sport has been an integral part of her life since childhood." /></Col>

          </Row>
        </Container>

      </div>



    </div>
  );
}

export default SixtyYearOfHansaflex;
