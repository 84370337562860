import React, { useEffect } from 'react';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgThaicontact from '../../assets/ContactLocation/Thaicontact2.png'
import imgHFTHBangok from "../../assets/ContactLocation/HFTHBangok.png"
import imgHFTHlamluka from "../../assets/ContactLocation/HFTHlamluka.png"



const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const units = [
        {
            name: 'HANSA-FLEX Hydraulics THAILAND Ltd',
            location: '15/288 Moo 11, Soi Nawamin 21, Klong Kum Subdistrict, Bueng Kum District, Bangkok 10230',
            telephone: ' : +66(0) 2  944 6944',
            fax: "",
            mail: 'thn@hansa-flex.com',
            image: imgHFTHBangok
        },
       
       
        {
            name: 'HANSA-FLEX Hydraulics (TH) LamLukka Branch',
            location: '88/112,88/13 Moo 5, LamLukKa District, Pathum Thani Province 12150',
            telephone: ': +66(0) 2 157 3866, 67, 68',
            fax: "",
            mail: ' thn@hansa-flex.com',
            image: imgHFTHlamluka
        },





    ];

    return (
        <div className="">
            <div>
                <CenterStage
                    imageSrc={imgThaicontact}
                    title={"Get in touch with HANSA-FLEX Thailand"}
                    paragraph={"Dive into a world of hydraulic expertise and personalised service. Contact us today and see how HANSA-FLEX Thailand can take your hydraulic operations to new heights."} />
            </div>
            <div className=''>
                <ContactUsCard units={units} />
            </div>
        </div>
    );
};

export default ContactUs;
