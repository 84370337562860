import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import stringSimilarity from "string-similarity";

const translationMap = new Map();
const similarityThreshold = 0.5; // Adjust this threshold as needed

async function loadTranslations() {
    try {
        const response = await fetch('/locales/th/translation.json'); // Adjust path for other languages as needed

        if (!response.ok) {
            throw new Error(`Failed to load translations: ${response.statusText}`);
        }

        const translations = await response.json();

        Object.keys(translations).forEach(key => {
            translationMap.set(key, translations[key]);
        });

        console.log("Translations loaded: " + translationMap.size);
    } catch (error) {
        console.error("Error loading translations:", error);
        alert("Failed to load translations. Please try again later.");
    }
}

function findSimilarKey(key) {
    let bestMatch = null;
    let bestMatchScore = 0;

    for (const mapKey of translationMap.keys()) {
        const similarity = stringSimilarity.compareTwoStrings(key, mapKey);
        if (similarity > bestMatchScore && similarity >= similarityThreshold) {
            bestMatchScore = similarity;
            bestMatch = mapKey;
        }
    }

    return bestMatch;
}

async function initializeI18n() {
    await loadTranslations();

    i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            debug: false,
            fallbackLng: "en",
            returnObjects: true,
            backend: {
                loadPath: '/locales/{{lng}}/translation.json',
            },
            saveMissing: true,
            missingKeyHandler: (lngs, ns, key, fallbackValue) => {
                const currentLanguage = i18n.language;

                if (currentLanguage === 'en') {
                    //console.warn(`Missing translation key '${key}' in language '${currentLanguage}'`);
                    return fallbackValue; // Skip custom logic for 'en' language
                }

                console.log("in fallback");

                const similarKey = findSimilarKey(key);
                let result = fallbackValue;

                if (similarKey) {
                    console.warn(`DEBUG i: Loosely matched translation key '${key}' with '${similarKey}'`);
                    result = translationMap.get(similarKey);
                } else {
                    console.log(`DEBUG irr: mtk: '${key}'`);
                }
                if (similarKey === key) {
                    console.log(`DEBUG i: mtksim: '${key}'`);
                }
                console.warn(`DEBUG ir: Returning translation: ${result}`);
                return result;
            },
        });
}


export default initializeI18n;
