import React, { useEffect } from 'react';
import ImageText from '../../Components/ImageText/ImageText';
import CenterStage from '../../Components/CenterStage/CenterStage';
import img1962 from '../../assets/History/1962.jpg'
import img1965 from '../../assets/History/1965.jpg'
import img1969 from '../../assets/History/1969.jpg'
import imghistory from '../../assets/History/imgHistory.jpg'
import img1972 from '../../assets/History 1970s/img1972.jpg'
import img1974 from '../../assets/History 1970s/img1974.jpg'
import img1975 from '../../assets/History 1970s/img1975.jpg'
import img1977 from '../../assets/History 1970s/img1977.jpg'
import ShowcaseCarousel from '../../Components/ShowcaseCarousel/ShowcaseCarousel';
import img2022 from '../../assets/History 2020s/img2022.jpg'

import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../Components/ImageCard/ImageCard';
import Familysince1962_new2 from '../../assets/History 2020s/Familysince1962_new2.png'
import imgHansaflexfigures from '../../assets/History 2020s/Hansaflexfigures.jpg'
import imgPartofstory from '../../assets/History 2020s/Partofstory.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import FullImageCarousel from '../../Components/FullImageCarousel/FullImageCarousel';
import img1980sA from '../../assets/History 1970s/1980sA.jpg'
import img1990S_A from '../../assets/The 1990s/1990S_A.jpg'
import img1990S_B from '../../assets/The 1990s/1990S_B.jpg'
import img1990S_C from '../../assets/The 1990s/1990S_C.jpg'
import img1990S_D from '../../assets/The 1990s/1990S_D.jpg'
import img1990S_E from '../../assets/The 1990s/1990S_E.jpg'
import img2010 from '../../assets/2010to2019/2010.jpg'
import img2010_B from '../../assets/2010to2019/2010_B.jpg'
import img2011 from '../../assets/2010to2019/2011.jpg'
import img2012 from '../../assets/2010to2019/2012.jpg'
import img2012_B from '../../assets/2010to2019/2012_B.jpg'
import img2012_C from '../../assets/2010to2019/2012_C.jpg'
import img2013 from '../../assets/2010to2019/2013.jpg'
import img2015 from '../../assets/2010to2019/2015.jpg'
import img2019 from '../../assets/2010to2019/2019.jpg'
import img1980A from '../../assets/1980S/1980A.jpg'
import img1980B from '../../assets/1980S/1980B.jpg'
import img1980C from '../../assets/1980S/1980C.jpg'
import img2000SA from '../../assets/2000S/2000SA.jpg'
import img2000SB from '../../assets/2000S/2000SB.jpg'
import img2000SC from '../../assets/2000S/2000SC.jpg'
import img2020SA from '../../assets/2020S/2020SA.jpg'
import img2020SB from '../../assets/2020S/2020SB.jpg'
import img2020SC from '../../assets/2020S/2020SC.jpg'

function GroupHistory() {

  const isMobile = window.innerWidth <= 768;


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const historyimg1962 = {
    image: img1962,
    title: ' 1962',
    description: 'Joachim Armerding founds the company Joachim Armerding Industriebedarf, laying the foundation for what would later become HANSA-FLEX AG. The company operates from his garage at his house in Achim near Bremen. Here, he assembled the first hose lines at his “green workbench”, which can still be seen today at the company headquarters.'
  }

  const historyimg1965 = {
    image: img1965,
    title: '1965 ',
    description: 'Increasing demand and customer insistence for high-quality hydraulic hoses led Joachim Armerding to set up his first workshop in a former plumber’s shop in Bremen. The first employee is hired in the same year.'
  }

  const historyimg1969 = {
    image: img1969,
    title: '1969',
    description: 'In the mid 1960s, annual turnover exceeds 100,000 DM for the first time. The second branch is opened in Osnabrück in an old joinery. Friend and companion Günter Buschmann manages this branch, joining the company as partner shortly thereafter.'
  }

  const historyimg2010 = {
    image: img2010,
    title: '2010',
    description: ' GmbH becomes HANSA- FLEX AG. The shares remain in the hands of the founding families. Thomas Armerding becomes chairman of the board of directors, with Uwe Buschmann serving as his deputy.'
  }

  const historyimg2012 = {
    image: img2012,
    title: '2012',
    description: 'HANSA-FLEX  opens its own trainee branch HANSA-FLEXAG near Kassel. The entire branch is managed by HANSA-FLEX  AG trainees. '
  }

  const historyimg2022 = {
    image: img2022,
    title: '2022',
    description: 'The Deputy Chairman of the Board of Directors, Uwe Buschmann, goes into his well-deserved retirement after 36 years in the business. Florian Wiedemeyer is appointed to the board of directors as CFO.'
  }

  const carouselItems = [
    {
      imgSrc: img1972,
      title: '1972',
      description: 'Renovation of the first headquarters is inaugurated in Bremen-Mahndorf. In the same year, the third branch opens its doors in Elze, Lower Saxony.'
    },
    {
      imgSrc: img1974,
      title: '1974',
      description: 'With the establishment of the fourth branch in Duisburg, the name Joachim Armerding Industriebedarf is no longer appropriate. The company is renamed HANSA-FLEX. '

    },
    {
      imgSrc: img1975,
      title: '1975',
      description: 'The first uniform company logo is introduced and used until 1989, featuring a hose together with a standard fitting. Even then, HANSA-FLEX red was used as the company colour. '
    },

    {
      imgSrc: img1977,
      title: '1977',
      description: ' With more than 25 employees, HANSA-FLEX achieves an anual turnover of over ten million Deutschmarks for the first time.'

    }
  ];




  const carouselItems2 = [
    {
      imgSrc: img1990S_A,
      title: '1990',
      description: 'The BJU/ASU names Joachim Armeding Entrepreneur of the Year for 1990. The first branch office in the "new" federal states opens in Schwerin.'
    },
    {
      imgSrc: img1990S_B,
      title: '1993',
      description: 'The automated small parts warehouse (AKL) at the head office in Breman begins operations. A second administration building is errected soon thereafter. '

    },
    {
      imgSrc: img1990S_C,
      title: '1995',
      description: 'The second generation of the founding families obtains head positions: Thomas Armeding and Uwe Buschmann are appointed managing directors. '
    },

    {
      imgSrc: img1990S_D,
      title: '1997',
      description: ' HANSA-FLEX opens its first branch outside European in Iskenderun, Turkey.'

    },
    {
      imgSrc: img1990S_E,
      title: '1999',
      description: ' With the opening of locations in Billings/Montana USA and Blumenau in Brazil, HANSA-FLEX is represented on the American continent for the first time.'

    }


  ];



  const carouselItems3 = [
    {
      imgSrc: img2010,
      title: '2010',
      description: 'HANSA-FLEX Gmbh becomes HANSA-FLEX AG. The shares remain in the hands of the founding families. Thomas Armending becomes chairman of the board of directors, with Uwe Buschmann serving as his deputy.'
    },
    {
      imgSrc: img2010_B,
      title: '2010',
      description: 'The second central warehouse is opened in Geisenfeld, supplying branches in southern Germany and southern Europe. '

    },
    {
      imgSrc: img2011,
      title: '2011',
      description: 'HANSA-FLEX receives DIN EN ISO 14001 certification - (environment management). '
    },

    {
      imgSrc: img2012,
      title: '2012',
      description: ' HANSA-FLEX celebrates its 50th anniversary. There are 384 braches and 269 vehicles of the Rapid Hydraulic Service worldwide.'

    },

    {
      imgSrc: img2012_B,
      title: '2012',
      description: ' HANSA-FLEX opens its own trainee branch near Kassel.The entire branch is managed by trainees.'

    },

    {
      imgSrc: img2012_C,
      title: '2012',
      description: ' Company founder Joachim Armerding establishes the HANSA-FLEX Foundation. Its aim is to promote education and training, including support for students.'

    },

    {
      imgSrc: img2013,
      title: '2013',
      description: ' HANSA-FLEX app HANSA-FLEX online shop make it easier to get in and touch and access information, services and products.'

    },

    {
      imgSrc: img2015,
      title: '2015',
      description: ' Company founder Joachim Arming passes away at the age of 80.Never one to quit working, Mr Armeding could always be found in his office at he company headquarters in Breman.'

    },

    {
      imgSrc: img2019,
      title: '2019',
      description: ' With Alina Armeding, Thorben Buschmann and Cedric Buschmann, the third generation of the founding families arrives at the company.'

    }


  ];




  const carouselData = [
    {
      imageSrc: img1980A,
      title: 'The 1980S',
      // subtitle: '',
      paragraph: '1980: In höxter, HANSA-FLEX Metallschlaunch GmbH is founded as the first business division outside standard hydaraulic applications. HANSA-FLEX now manufactures specific metal hose assemblies for the food and chemical industries.',
      // listItems: ['Item 1', 'Item 2', 'Item 3'],
    },
    {
      imageSrc: img1980B,
      title: 'The 1980S',
      // subtitle: 'Subtitle 2',
      paragraph: '1980: HANSAFLEX enters the digital age. At the beginning of 1980, the eight branches receive IT systems networked with the head office in Breman.',
      // listItems: ['Item 4', 'Item 5', 'Item 6'],
    },
    {
      imageSrc: img1980C,
      title: 'The 1980S',
      // subtitle: 'Subtitle 3',
      paragraph: '1986: Thomas Armeding anf Uwe buschmann, sons of the comapany founders,join the company as regular employees and get to know the business from the bottom up- a futuristic step to ensure HANSA-FLEXs continued quality and focus as a faamily-run company.',
      // listItems: ['Item 7', 'Item 8', 'Item 9'],
    },
  ];

  const carouselData2 = [
    {
      imageSrc: img2000SA,
      title: 'The 2000S',
      // subtitle: '',
      paragraph: '2001: HANSA-FLEX expands its range to include a mobole service. The Rapid Hydraulic Service is founded halfway through the year, with 26 vehicles deployed nationwide.',
      // listItems: ['Item 1', 'Item 2', 'Item 3'],
    },
    {
      imageSrc: img2000SB,
      title: 'The 2000S',
      // subtitle: 'Subtitle 2',
      paragraph: '2001: HANSA-FLEX opens its own in-house training centre for employees. The Hydraulic Components Department (HKO) begins operations right next door.The company now has 167 branches worldwide.',
      // listItems: ['Item 4', 'Item 5', 'Item 6'],
    },
    {
      imageSrc: img2000SC,
      title: 'The 2000S',
      // subtitle: 'Subtitle 3',
      paragraph: '2009: HANSA-FLEX opens its central Asian headquarters in Shanghai, China, now organising purchasing, training and production in the region, and supplying all nearby branches in Asia. The first branch office in China was established in November 2003.',
      // listItems: ['Item 7', 'Item 8', 'Item 9'],
    },
  ];


  const carouselData3 = [
    {
      imageSrc: img2020SA,
      title: 'The 2020S',
      // subtitle: '',
      paragraph: "2020: Groundbreaking ceremony for the largest construction project in the company's history.The expansion of the company headquarters is built in Breman-Mahndorf. The total investment amounts to more than 22 million euro.",
      // listItems: ['Item 1', 'Item 2', 'Item 3'],
    },
    {
      imageSrc: img2020SB,
      title: 'The 2020S',
      // subtitle: 'Subtitle 2',
      paragraph: '2021: Co-founder, long-time Managing Director, Shareholder and Honary Chairman of the Supervisory Board Günter Bushmann passes away at the age of 85. ',
      // listItems: ['Item 4', 'Item 5', 'Item 6'],
    },
    {
      imageSrc: img2020SC,
      title: 'The 2020S',
      // subtitle: 'Subtitle 3',
      paragraph: '2022: The Deputy Chairman of the board of Directors, Uwe Buschman, goes into his well-deserved retirement after 36 years in the bussiness. Florian Wiedemeyer is appointed to the board of directors as CFO.',
      // listItems: ['Item 7', 'Item 8', 'Item 9'],
    },
  ];




  return (
    <div>
      <div>
        <CenterStage
          imageSrc={imghistory}
          title={"A FOCUS ON CUSTOMERS AND FLEXIBILITY SINCE 1962"}
          paragraph={"The history of HANSA-FLEX began six decades ago in a small garage in Achim, near Bremen. In 1962, businessman Joachim Armerding starts manufacturing and selling hydraulic hose lines, laying the foundation for today’s HANSA-FLEX AG. The company sees plenty of growth in Germany in the following years before going international in 1989. Today, HANSA-FLEX is a global family business with foreign subsidiaries on all five continents."}
        />
      </div>

      <div>
        <SectionHeader
          title={"THE 1960S"}
          subtitle={""}
        />

      </div>

      <div className='div-gray'>


        <div >
          <br />
          <ImageText data={historyimg1962} left={false} /> <br /><br />
        </div>

        <div >
          <br />
          <ImageText data={historyimg1965} /> <br /><br />
        </div>

        <div >
          <br />
          <ImageText data={historyimg1969} left={false} /> <br /><br />
        </div>


        <div>
          <SectionHeader
            title={"THE 1970S"}
            subtitle={""}
          />

        </div>


      </div>

      <div>
        <ShowcaseCarousel carouselItems={carouselItems} isExploreButton={false} descDisplay='block' carouselType={2} />
      </div>

      <div>
        <br />
        <br />
        <br />
        <FullImageCarousel carouselItems={carouselData} />

      </div>

      <div>
        <br />
        <br />
        <br />
        <ShowcaseCarousel carouselItems={carouselItems2} isExploreButton={false} descDisplay='block' carouselType={2} />
      </div>


      <div>
        <br />
        <br />
        <br />
        <FullImageCarousel carouselItems={carouselData2} />

      </div>

      <div>
        <br />
        <br />
        <br />
        <ShowcaseCarousel carouselItems={carouselItems3} isExploreButton={false} descDisplay='block' carouselType={2} />
      </div>










      <div>
        <br />
        <br />
        <br />
        <FullImageCarousel carouselItems={carouselData3} />

      </div>




      <div>
        <SectionHeader
          title="YOU MIGHT ALSO BE INTERESTED IN THESE TOPICS" />
        <Container className="custom-container">
          <Row>


          </Row>
        </Container>
      </div>


      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4}><ImageCard image={Familysince1962_new2} learnMoreLink={"/hf-group-management"}
            title="Family run since 1962"
            desc={"HANSA-FLEX is a family-run company. The three-member board of directors is headed by Thomas Armerding, son of the company’s founder."} /></Col>

          <Col sm={12} md={6} lg={4}><ImageCard image={imgHansaflexfigures} learnMoreLink={"https://www.Hansa-Flex.de/en/company/"}
            title="HANSA-FLEX current figures, data and facts"
            desc={"Find out about the most important current key figures of HANSA-FLEX AG, including the group’s recent economic development."} /></Col>

          {/* <Col sm={12} md={6} lg={4}><ImageCard image={imgPartofstory} learnMoreLink={"/hf-group-history"}
            title="You want to become part of our story?"
            desc={"We are looking for new colleagues with a passion for their work and great expertise in their fields for various commercial and technical positions at several locations."} /></Col> */}

        </Row>
      </Container>




    </div>
  );
}

export default GroupHistory;