import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgmanagement from '../../assets/Mangement-group/imgManagement.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import ContactBubble from '../../Components/ContactBubble/ContactBubble';
import imgThomas from '../../assets/Mangement-group/imgThomas.jpg';
import imgChristian from '../../assets/Mangement-group/imgChristian.jpg';
import imgFlorian from '../../assets/Mangement-group/imgFlorian.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../Components/ImageCard/ImageCard';
import imgValuebasedcooperation from "../../assets/Mangement-group/Valuebasedcooperation.jpg"
import imgFocused1962_history from "../../assets/Mangement-group/Focused1962_history.jpg"

function GroupManagement() {


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);
  return (
    <div>
      <div>
        <CenterStage
          imageSrc={imgmanagement}
          title={"INDEPENDENTLY AND FAMILY RUN "}
          paragraph={"The name HANSA-FLEX has stood for quality and innovation since 1962. Even as a globally active company with worldwide expansion and an ever-increasing product portfolio, HANSA-FLEX still is – and will always view itself as – a family business. The strategic management of the company is in the hands of the three-member board of directors, headed by Thomas Armerding, son of the company’s founder, Joachim Armerding."}
        />
      </div>

      <div className='div-gray center-all-items'>
        <SectionHeader
          title='THE HANSA-FLEX AG BOARD OF DIRECTORS' />

        <ContactBubble
          imageSrc={imgThomas}
          title="Thomas Armerding"
          subTitle="Chairman of the Board of Director"
          description="Thomas Armerding has been managing the HANSA-FLEX organization since the mid 1990s. A banker by trade, he is the son of the company’s founder, Joachim Armerding. He joined the company as a trainee in 1986 and has worked in all departments at the Bremen headquarters. In 1989 he was appointed branch manager in Oldenburg. He then became a Managing Director together with Uwe Buschmann in 1994. The establishment and expansion of HANSA-FLEX on the international stage is largely due to his initiative"
          departments={['Company Development', 'Foreign Business', 'Sales', 'Marketing']}
        />

        <ContactBubble
          imageSrc={imgChristian}
          title=" Christian-Hans Bültemeier"
          subTitle="Chief Operating Officer"
          description="Christian-Hans Bültemeier has been COO and Deputy Chairman of the Board of Directors since 2022. A business graduate, he joined HANSA-FLEX in 2010 as Head of Administration and Finance. He was previously a member of the executive board of a German subsidiary of a US life science technology company. In January 2013, he was appointed to the board of directors of HANSA-FLEX AG as CFO and entrusted with the management of the Finance, Human Resources, IT and Commercial Services departments"
          departments={['Supply Chain', 'Production', 'Purchasing and Quality', 'Research and Development']}
        />

        <ContactBubble
          imageSrc={imgFlorian}
          title="Florian Wiedemeyer"
          subTitle="Commercial Manager & CFO"
          description="Florian Wiedemeyer was appointed to the board of directors as CFO in January 2022. The business graduate (FH) started at HANSA-FLEX in October 2000 as part of a dual study programme. Following further in-service studies, he established a company-wide reporting system and was tasked with setting up and managing the Controlling Department. Since 2013, he has been making a significant contribution to the company’s recent success story as Head of Accounting and Controlling"
          departments={['Corporate Finance', 'Human Resources and Information Technology', 'Accounting and Controlling', 'Commercial Services']}
        />

</div>

<div className='div-WHITE center-all-items'>
          <SectionHeader
              title="YOU MIGHT ALSO BE INTERESTED IN THESE TOPICS"
              subtitle=""
          />

      </div>

      <Container className="custom-container">
              <Row>
                  <Col sm={12} md={6} lg={4}><ImageCard image={imgValuebasedcooperation} learnMoreLink={"/hf-group-mission-statement"}
                      title="Value-based cooperation"
                      desc={"HANSA-FLEX stands for fairness, respect and transparency. Our Mission Statement and Code of Conduct apply at all locations worldwide."} /></Col>

                  <Col sm={12} md={6} lg={4}><ImageCard image={imgFocused1962_history} learnMoreLink={"/hf-group-history"}
                      title="Focused on customers since 1962"
                      desc={"From a one-man company to a global family business, HANSA-FLEX first began in a garage in Achim, near Bremen, in 1962."} /></Col>

    
              </Row>
          </Container>



</div>

);
}

export default GroupManagement;