import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import Promoter from '../../../Components/Promoter/Promoter';
import ImageText from '../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';
import HFTabs from '../../../Components/HfTabs/HfTabs';


import imgStage from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/Stage.jpg'
import ManyAreas from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/ManyAreas.jpg'
import HydraulicHoseVarients from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/HydraulicHoseVarients.jpg'
import FittingsAndConnections from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/FittingsAndConnections.jpg'
import HoseProtectionAndSafety from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/HoseProtectionAndSafety.jpg'
import HoseManagementRequired from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/HoseManagementRequired.jpg'
import XCodeManager from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/XCodeManager.jpg'
import ClickAndCollect from '../../../assets/Products/HosesAndHoseLines/Hose Line Configurator/ClickAndCollect.jpg'


//TODO: TextPoster

function HoseLineConfigurator() {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    // features card carousel data
    const featuresData = [
        {
            title: "Select and customise",
            description: "Choose from around 90 hoses and 600 fittings and configure them individually.",
            image: imgMark
        },
        {
            title: "Also mobile usable",
            description: "Configure conveniently on the move using your smartphone or tablet.",
            image: imgMark
        },
        {
            title: "Click & Collect Option",
            description: "Collection within 60 minutes of ordering from one of our 200 branches.",
            image: imgMark
        },
        {
            title: "Reorder with two clicks",
            description: "With the X-CODE you can reorder hose assemblies with two clicks.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Many areas of application",
            description: "The wide definition range of the parameters in the hose line configurator enables the individual configuration of hose lines for most common hydraulic applications.  ",
            listItems: [" A total of around 90 hose materials", "Nominal diameters from 6 mm to 32 mm ", "Temperature range from - 40° C to + 100° C ", "Lengths between 400 mm and 40,000 mm ", "Maximum dynamic operating pressure up to 500 bar"],
            image: ManyAreas
        },
        {
            title: "Hydraulic hose variants",
            description: "The hose configurator automatically provides the appropriate hydraulic hose variants for selection based on the nominal width and the maximum dynamic operating pressure. Which hose variant is the best option in each case depends on the individual requirements.",
            listItems: ["Elastomer hydraulic hoses for the most common requirements ", "Compact hoses for tight installation space, for example in mobile hydraulics", "Thermoplastic hoses for particularly low weight"],
            image: HydraulicHoseVarients
        },
        {
            title: "Fittings and connection types",
            description: "The hose line configurator automatically compiles a clear selection of fittings and connection types from around 600 materials that optimally match the configured hose. For quicker identification of the desired product, both the standard market designation and the HANSA-FLEX designation are listed in the selection menu. ",
            listItems: ["Press nipples for medium and high pressure applications", "Press fittings type Interlock for extreme loads", "Non-peel press frames as well as peel press frames"],
            image: FittingsAndConnections
        },
        {
            title: "Hose protection and safety accessories",
            description: "Hydraulic hose lines can be damaged by thermal or mechanical stress. The hose protection accessories that can be optionally selected in the hose line configurator protect hose lines from abrasion stress and help prevent personal injury.",
            listItems: ["Heat-resistant plastic scuff protection coil up to 120° C", "Nylon protective hose for protection against oil jet injuries", "Particularly robust scuff protection coil made of spring steel wire"],
            image: HoseProtectionAndSafety
        },
    ];

    const tabTitles = [
        { title: "Many areas of application", eventKey: "Many areas of application" },
        { title: "Hydraulic hose variants", eventKey: "Hydraulic hose variants" },
        { title: "Fittings and connection types", eventKey: "Fittings and connection types" },
        { title: "Hose protection and safety accessories", eventKey: "Hose protection and safety accessories" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"FASTER TO THE DESIRED PRODUCT WITH THE HOSE LINE CONFIGURATOR"}
                    paragraph={"The HANSA-FLEX hose line configurator is a real milestone. With our digital hydraulic hose configurator, you can easily configure (hydraulic) hose lines yourself. In five steps, you can create your customised product online from around 90 hoses and 600 fittings in just a few minutes. Order with shipping or click & collect and place repeat orders with just two clicks thanks to X-CODE."}
                />

            </div>

            <div>
                <SectionHeader
                    title={"INDIVIDUAL HOSE ASSEMBLIES IN THE BEST HANSA-FLEX QUALITY"}
                    subtitle={"Take advantage of the digital hose line configurator and benefit from analogue craftsmanship, because each of your individually configured hose lines is hand-assembled by our quality assemblers."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO: Steps */}

            <div>
                <SectionHeader
                    title={"EXTENSIVE CONFIGURATION OPTIONS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"DISCOVER THE POSSIBILITIES OF THE HOSE LINE CONFIGURATOR"}
                />
            </div>

            {/* TODO: Steps */}

            {/* <div>
                <VideoPlayer videoUrl="https://www.youtube.com/embed/4coN3gVRfRA?si=gPPZSqGg-2IyrDVJ" />
            </div> */}

            <div>
                <SectionHeader
                    title={"HANSA-FLEX OFFERS A COMPREHENSIVE RANGE OF HYDRAULIC SERVICES"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={HoseManagementRequired}
                                title="Hose management entirely as required"
                                desc={"HANSAFLEX supports you with 360° hose management from manufacture to disposal, from individual services to all-round carefree packages."} learnMoreLink={"/hose-management"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={XCodeManager}
                                title="X-CODE Manager for digital hose management"
                                desc={"The X-CODE Manager makes hose management easier and clearer. The convenient software clearly displays stock levels and inspection intervals."} learnMoreLink={"/x-code-manager"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={ClickAndCollect}
                                title="Click & Collect makes ordering even more convenient"
                                desc={"Click & Collect combines the convenient processes of online ordering with personal contact and on-site consultation options for collection at the branch."} learnMoreLink={"/"} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default HoseLineConfigurator;