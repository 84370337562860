import React from 'react';
import './FullImageCard.css';
import { Col, Row } from 'react-bootstrap';
import { BsCheckLg } from "react-icons/bs";
import { useTranslation } from 'react-i18next';

const FullimageCard = ({ imageSrc, title, subtitle, paragraph, listItems }) => {
    const { t } = useTranslation();
    const imageStyle = {
        width: '100%',
        height: '500px',
        objectFit: 'cover',
        padding: '0px',
    };

    return (
        <div className="fic-stage-container">
            <div className="fic-stage-image-container">
                <img
                    className='fic-img-center-stage'
                    src={imageSrc}
                    alt="First slide"
                    style={imageStyle}
                />
            </div>

            <Row className="fic-stage-text-container">
                <Col xs={12} sm={12} md={4} lg={4}>
                    <div className="fic-left-part">
                        <h2 className='fic-title'>{t(title)}</h2>
                        <h3 className='fic-subtitle'>{t(subtitle)}</h3>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={8} lg={8}>
                    <div className="fic-right-part">
                        <p className='fic-para'>{t(paragraph)}</p>
                        <ul>
                            {listItems && listItems.map((item, index) => (
                                <li key={index} className='fic-check-mark'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                </svg> <span> {item}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default FullimageCard;
