import React from 'react';
import { Carousel } from 'react-bootstrap';
import FullimageCard from '../FullImageCard/FullimageCard';
import { useTranslation } from 'react-i18next';
const FullImageCarousel = ({ carouselItems }) => {
    const { t } = useTranslation();
    return (
        <Carousel>
            {carouselItems.map((item, index) => (
                <Carousel.Item key={index} interval={3000}>
                    <FullimageCard
                        imageSrc={item.imageSrc}
                        title={t(item.title)}
                        subtitle={t(item.subtitle)}
                        paragraph={t(item.paragraph)}
                        listItems={t(item.listItems)}
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default FullImageCarousel;
