import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import './ContactBubble.css';
import { FaCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const ContactBubble = ({ imageSrc, title, subTitle, description, departments }) => {
  const { t } = useTranslation();
  return (
    <div className="center-container">
      <div className="center-content">
        <Container className='Container-ContactBubble' fluid>
          <Row>
            {imageSrc && (
              <Col className='custom-col1'>
                <div className='img-ContactBubble'>
                  <img src={imageSrc} alt={title} />
                </div>
              </Col>
            )}
            <Col className='custom-col2'>
              <div>
                {title && <h1 className='ContactBubble-Heading'>{t(title)}</h1>}
                {subTitle && <p className='ContactBubble-SubHeading'>{t(subTitle)}</p>}
                {description && <p className='Desc-ContactBubble'>{t(description)}</p>}
                {departments && departments.length > 0 && (
                  <>
                    <h2 className='ContactBubble-ListHeading'>{t("Departments")}:</h2>
                    <ol style={{ listStyle: 'none', paddingLeft: 0 }}>
                      {departments.map((dept, index) => (
                        <li key={index} style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
                          <FaCircle style={{ marginRight: '0.5rem', fontSize: '0.5em', color: '#30557d' }} />
                          {dept}
                        </li>
                      ))}
                    </ol>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

ContactBubble.propTypes = {
  imageSrc: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  departments: PropTypes.arrayOf(PropTypes.string),
};

export default ContactBubble;
