import React, { useEffect } from 'react';
import CertificateCard from '../../Components/CertificateCard/CertificateCard';

import imgBelgium_20152 from '../../assets/Certificates/Belgium_20152.jpg';
import imgBelgium_2015 from '../../assets/Certificates/Belgium_2015.jpg';
import imgBelgium_2018 from '../../assets/Certificates/Belgium_2018.jpg'
import imgThailand_Certificate from '../../assets/Certificates/Thailand_Certificate.png'



function Certificates() {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <CertificateCard title={"Thailand Certification DIN EN ISO 9001-2015 "} description={"'Development, production, trading, technical consulting and services for all aspects of fluid technology (Head Office)','Production, trading, technical consulting and services for all aspects of fluid technology (Affiliate Company)','Production and technical consulting for all aspects of fluid technology (Production area),'Production, technical consulting and services for all aspects of fluid technology (Mobile service)'."} imageUrl={imgThailand_Certificate} />
            <CertificateCard title={"Certification DIN EN ISO 14001:2009"} description={"HANSA-FLEX successfully achieved ISO 14001 certification, demonstrating its commitment to environmental management. This certification, awarded after an audit by Germanischer Lloyd, covers our Pipebending Centre, Special Pipe and Fittings Production, Metal Hose Production, general hose line production, and corporate headquarters in Bremen, including the central warehouse and quality assurance departments."} imageUrl={imgBelgium_20152} />
            <CertificateCard title={"Certificate DIN EN ISO 45001:2018"} description={"HANSA-FLEX has achieved ISO 45001:2018 certification for Occupational Health and Safety in our Mobile Services sector, including Industrial and Rapid Hydraulic Services. This certification aims to minimize hazards and reduce accidents, ensuring all employees return home in the same condition as when they arrived. Safety is a collective responsibility at HANSA-FLEX."} imageUrl={imgBelgium_2018} />
           
        </div>
    );
}

export default Certificates;