import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'

import imgStage from '../../assets/Products/TechnicalInformation/stage.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';

const TechnicalInformation = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"THE RIGHT HANSA-FLEX HYDRAULIC MOTOR FOR YOUR APPLICATION"}
                    paragraph={"Hydraulic motors transform hydraulic energy into mechanical energy. We offer a wide range of geroller and gerotor motors matched to the performance data of your machines and systems in proven quality as premium or economy variants for numerous applications in mobile and industrial hydraulics. We can provide other types of hydraulic motors such as axial, gear and radial piston motors on request."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"TECHNICAL INFORMATION TO DOWNLOAD"}
                    subtitle={"Here you can find all overviews, technical information and our quick reference brochure for download in German and English as a PDF."}
                // TODO : Steps
                />
            </div>

        </div>
    )
}


export default TechnicalInformation