import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import ContactBubble from '../../Components/ContactBubble/ContactBubble';


import bgImg from '../../assets/hose.jpg'
import imgKitisakSuwanwong from '../../assets/management/KitisakSuwanwon.png';
import imgBRNuaChan from '../../assets/management/BRNuaChan.png';
import imgBRThanasak from '../../assets/management/BRThanasak.jpg';


function Management() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <CenterStage title={"Meet our Management"} imageSrc={bgImg} paragraph={"At HANSA-FLEX, our management is influential in guiding the company with strategic vision and operational excellence. Through dynamic leadership and a focus on innovation, we prioritize customer satisfaction and sustainable growth. Our management fosters a culture of collaboration and empowerment, driving success across all facets of the organization."} />

            <div>
                <ContactBubble
                    imageSrc={imgKitisakSuwanwong}
                    title="Mr. Kitisak Suwanwong"
                    subTitle="Managing Director"
                    description="As the founder of HANSA-FLEX Thailand Co., Ltd. since 2003, I am committed to creating an organization that is a leader in hydraulic system and stainless steel hose solutions in Thailand and the ASEAN region. We will continuously develop our products and services to meet the needs of our customers and maximize the benefits that they will receive from our services, while creating a happy and professional organization. I believe that with everyone’s cooperation, we will be able to achieve our goals."
                />
                <ContactBubble
                    imageSrc={imgBRThanasak}
                    title="Mr. Thanasak Saipreecha "
                    subTitle="Branch Manager -LamLukKa"
                    description="Mr. Thanasak Saipreecha, an expert in hydraulics and stainless steel hoses, with over 21 years of experience since the establishment of HANSA-FLEX Thailand is ready to provide advice and solve problems related to your hydraulic system. With a deep understanding of our products, we can precisely select the right solution for your needs. Be assured of fast and efficient after-sales service."
                />

                <ContactBubble
                    imageSrc={imgBRNuaChan}
                    title="Ms. Ratchanu Manee"
                    subTitle="Branch Manager - NuaChan"
                    description="Ms. Ratchanu Manee, a hydraulic and stainless steel hose expert with over 21 years of experience since the establishment of HANSA-FLEX Thailand is ready to provide advice and solve your hydraulic system problems. With a deep understanding of our products, we can precisely select the right solution for your needs. Be assured of fast and efficient after-sales service."
                />
            </div>
        </div>
    );
}

export default Management;