import React, { useEffect } from 'react';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import TextCaption from '../Components/TextCaption/TextCaption';

function PrivayPolicyPage() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    return (
        <div>

            {/* <TextCaption
                paragraph={"The policy also explains the choices users have with respect to the collection, use, and sharing of their personal information. By providing this information, we aim to empower our users to make informed decisions about how their personal data is used and to ensure that their rights are respected. "} /> */}

            {/* <TextCaption paragraph={"Additionally, the policy provides transparency about the practices and procedures we have in place to protect our users' personal information from unauthorized access, disclosure, and misuse. By maintaining these high standards of data protection, we strive to maintain the trust and confidence of our customers, website visitors, and users. "} /> */}
            {/* <TextCaption title={" Legal Disclaimer "} paragraph={" Thank you for visiting the HANSA-FLEX Thailand website. By accessing and using this website, you accept and agree to comply with the terms and conditions outlined in this legal disclaimer. If you do not agree with any part of these terms and conditions, please do not use our website. "}
                bulletPoints={["1. Accuracy of Information HANSA-FLEX Thailand strives to provide accurate and up-to-date information on this website. However, we do not warrant the accuracy, completeness, or reliability of any information, content, or materials provided herein. Users acknowledge that any reliance on such information is at their own risk. "
                    , "2. Use of Content The content on this website, including but not limited to text, images, graphics, and other materials, is provided for informational purposes only. It is protected by copyright, trademark, and other intellectual property laws. Users may not modify, reproduce, distribute, transmit, display, or perform any content without prior written consent from HANSA-FLEX Thailand. "
                    , "3. Third-Party Links This website may contain links to third-party websites for convenience and informational purposes. HANSA-FLEX Thailand does not endorse or take responsibility for the content, accuracy, or privacy practices of such websites. Users access third-party websites at their own risk. "
                    , "4. Limitation of Liability HANSA-FLEX Thailand, its affiliates, directors, employees, or agents shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with the use or inability to use this website, even if advised of the possibility of such damages. "
                    , "5. Changes to Terms HANSA-FLEX Thailand reserves the right to modify, amend, or update these terms and conditions at any time without prior notice. Users are responsible for reviewing this legal disclaimer periodically to stay informed of any changes. "
                    , "6. Governing Law This legal disclaimer shall be governed by and construed in accordance with the laws of Thailand. Any disputes arising out of or relating to this disclaimer shall be subject to the exclusive jurisdiction of the courts in Thailand. "
                    , "7. Copyright All content on this website is protected by copyright laws. Unauthorized use of any materials may violate copyright, trademark, and other laws. HANSA-FLEX Thailand reserves all rights not expressly granted in and to the website and its content. "
                    , "8. Legal Information provided on this website is not intended as legal advice. Users should consult legal professionals for specific advice related to their individual circumstances."
                    , "9. Virus/Interceptions HANSA-FLEX Thailand makes efforts to ensure that this website is free from viruses or other harmful components. However, we cannot guarantee that the website will be secure or free from bugs or viruses. Users are responsible for implementing sufficient procedures and virus checks (including anti-virus and other security checks) to satisfy their particular requirements for the accuracy of data input and output "
                    , "If you have any questions or concerns regarding this legal disclaimer, please contact us at bris.sales@hansa-flex.com "
                ]} /> */}


            <TextCaption title={"Privacy Policy:"} paragraph={"At HANSA-FLEX Thailand, we are committed to protecting the privacy and confidentiality of personal information collected from users of this website. This privacy policy outlines how we collect, use, disclose, and safeguard your information in compliance with Thailand privacy laws, including the Privacy Act 1988 and the Thailand Privacy Principles (APPs). "}
                bulletPoints={["1. Information Collection We may collect personal information such as name, email address, phone number, and company name when voluntarily submitted by users through forms on this website. We may also collect non-personal information such as browser type, IP address, and operating system automatically through cookies and other tracking technologies. "
                    , "2. Purpose of Collection We collect personal information for purposes such as responding to inquiries, providing requested services, processing orders, improving our website and services, and sending marketing communications (with opt-out options provided). We will only use or disclose personal information for the purposes for which it was collected unless consent is obtained or as required by law. "
                    , "3. Consent By providing us with your personal information, you consent to the collection, use, and disclosure of that information as described in this privacy policy. You may withdraw consent at any time, subject to legal or contractual restrictions, by contacting us using the information provided below "
                    , "4. Disclosure of Information We may disclose personal information to our affiliates, service providers, or other third parties as necessary to fulfill the purposes for which it was collected or as required by law. We take reasonable steps to ensure that third parties adhere to privacy and security standards comparable to ours. "
                    , "5. Data Security We implement appropriate technical, physical, and organizational measures to safeguard the confidentiality, integrity, and availability of your information. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security. "
                    , "6. Data Transfer Personal information collected by HANSA-FLEX Thailand may be stored and processed in Thailand or other countries where our affiliates or service providers are located. By using our website or providing your personal information, you consent to the transfer of information to countries outside your country of residence, including Thailand, which may have different data protection laws. "
                    , "7. Data Retention We retain personal information for as long as necessary to fulfill the purposes outlined in this privacy policy or as required by law. We will securely delete or anonymize personal information no longer needed. "
                    , "8. Access and Correction You have the right to access, correct, update, or delete your personal information held by HANSA-FLEX Thailand. To exercise these rights or inquire about our privacy practices, please contact us using the information provided below."
                    , "9. Children's Privacy This website is not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children. If you believe that a child has provided us with personal information, please contact us, and we will take appropriate steps to delete such information. "
                    , "10. Changes to Policy: We reserve the right to update or revise this privacy policy at any time to reflect changes in our practices, legal requirements, or technological advancements. We will post the revised policy on this website with the effective date indicated. Your continued use of this website after any changes indicates your acceptance of the updated policy. "
                    , "11. Compliance with Laws: We are committed to complying with all applicable laws and regulations governing the collection, use, and protection of personal information, including Thailand Federal Law No. 45 of 2021 on the Protection of Personal Data and other relevant privacy legislation. "
                    , "12. Contact Information If you have any questions or concerns regarding this privacy policy, or if you wish to exercise your rights or contact our Privacy Officer, please contact us at thn@hansa-flex.com "
                ]} />


        </div>
    );
}

export default PrivayPolicyPage;