import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import Promoter from '../../../Components/Promoter/Promoter';
import ImageText from '../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';
import HFTabs from '../../../Components/HfTabs/HfTabs';


import imgHose from '../../../assets/hose.jpg'
import imgHydraulicHoses from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/hydraulic-hoses.jpg'
import imgMetalHose from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/metalHose.jpg'
import imgBluePipe from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/bluePipe.jpg'
import imgjetSpray from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/jetSpray.jpg'
import imgIndustrailHoses from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/industrialHoses.jpg'
import imgperforemdHoses from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/performedHoses.jpg'
import imgaccessoresHoseLine from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/accessoriesHoseLines.jpg'
import imgTabletMobile from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/tabletMobile.png'
import imgHoseforcompressed from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/hoseForCompressed.jpg'
import imgThePremiumHose from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/thePremiumHose.jpg'
import imgSwitchHoseLine from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/switchHoseLines.jpg'
import imghosekit from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/hoseKits.jpg'
import imgModernSeries from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/modernSeries.jpg'
import imgCustomisedHose from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/customisedHose.jpg'
import imgFittings from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/Fittings.jpg'
import imgCouplings from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/Couplings.jpg'
import imgAdapters from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/Adapters.jpg'


//TODO: TextPoster

function HosesAndHoseLines() {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const dataPipeFittings = {
        image: imgHoseforcompressed,
        title: 'Hoses for Compressed Air Technology',
        description: 'Our online store has a wide selection of pneumatic hoses in a variety of diameters, materials, and lengths.',
        listItems: ["Compressor and brake hoses", "PA, PE, PUR, PVDF, and PVC hoses", "Tubing, pipe flanges, fittings, and connections"]
    }

    // Product Development data

    const dataProductDevelopment1 = {
        image: imgThePremiumHose,
        title: 'The premium hose line for high pressures',
        description: 'The HANSA-FLEX HD 700 LL premium hose line has been specially developed for use in demanding conditions. It combines maximum performance with a long service life.',
        listItems: ["Excellent pressure load capacity, even after 2 million impulse cycles", "Available in sizes from DN 31 to DN 51", "DNV approval for maritime use"],
        buttonName: "MORE HYDRAULIC HOSES",
        buttonLink: "https://shop.hansa-flex.be/nl_NL/hoses/hydraulic-hoses/spiral-hoses/hd-700-ll/p/MAS_HD_700_LL"
    }

    const dataProductDevelopment2 = {
        image: imgSwitchHoseLine,
        title: 'Switching hose lines in seconds using the SSKV coupling',
        description: 'The push-in shell clamp connectors for hydraulic hoses combine the advantages of conventional couplings and fittings to reduce the amount of work and time involved in changing hose lines.',
        listItems: ["Additionally, it is secured by a manually operated serrated screw.", "Protected against unintentional release under pressure", "Tool-free assembly makes it ideal for tight installation spaces"],
        buttonName: "MORE SSNK COUPLINGS",
        buttonLink: "https://shop.hansa-flex.be/nl_NL/hose-fittings/push-in-clamping-shell-connections-sskv/c/webcat_HF_SAR_1320"
    }

    // Features card carousel data
    const featuresData = [
        {
            title: "Pre-assembled flexible tubes",
            description: "We pre-assemble all types of hoses available on the market into ready-to-install custom hose lines.",
            image: imgMark
        },
        {
            title: "Hose testing",
            description: "As officially authorized persons for hydraulic equipment inspection, our experts can inspect and test hydraulic and industrial hoses.",
            image: imgMark
        },
        {
            title: "Intelligent hose management",
            description: "With the HANSA-FLEX X-CODE Manager, you have all the information about the hoses at your fingertips, such as inspection intervals.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    // HfTab data
    const imageTextData = [
        {
            title: "Hose Fitting for all applications",
            description: "Accessories designed and manufactured by HANSA-FLEX are perfect for use with the intended types of hoses. They ensure a secure, leak-free connection.",
            listItems: ["Crimping, interlocking, and special fittings", "All nominal sizes and connection types available on the market", "Corrosion-resistant zinc-nickel surface coating"],
            buttonName: "LEARN MORE",
            buttonLink: "/hose-fitting",
            image: imgFittings
        },
        {
            title: "Safe and reliable hydraulic couplings",
            description: "Couplings are the best choice in situations where fittings need to be quickly changed, machines securely connected, or hoses heavily loaded in mobile and stationary hydraulic systems need to be replaced.",
            listItems: ["A quick coupling suitable for every application", "Available in various materials, such as steel or stainless steel", "Numerous sizes and sealing types"],
            buttonName: "LEARN MORE",
            buttonLink: "/coupling",
            image: imgCouplings
        },
        {
            title: "Make the right connection with hydraulic adapters",
            description: "Adapters create a secure, leak-free connection between components with different thread types. HANSA-FLEX offers a wide range of hydraulic adapters.",
            listItems: ["Threaded and transition adapters", "All types and standards of connection available on the market", "Special types, such as 45° SAE connections, are also provided"],
            buttonName: "LEARN MORE",
            buttonLink: "/adapters",
            image: imgAdapters
        },
    ];

    const tabTitles = [
        { title: "Fitting", eventKey: "Fitting" },
        { title: "Couplings", eventKey: "Couplings" },
        { title: "Adapters", eventKey: "Adapters" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgHose}
                    title={"HIGH-QUALITY HOSES FROM HANSA-FLEX"}
                    paragraph={"The HANSA-FLEX range includes hoses for hydraulic and pneumatic applications, as well as for the transport of chemicals, food, air, water, and abrasive media. In addition to standard hoses, we also provide suction hoses, positive pressure hoses, suitable accessories, and pre-assembled hose lines manufactured according to your individual needs. As a full-service provider, we also support our customers in installation, maintenance, and inspection."}
                />
            </div>
            <SectionHeader
                title={"THE COMPLETE RANGE OF HANSA-FLEX COUPLINGS"}
                subtitle={"HANSA-FLEX is recognized as an extremely competent system supplier for high-quality hydraulic piping. But our expertise goes much further:"}
            />
            <div>
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={8} ><ImageCard image={imgHydraulicHoses} learnMoreLink={"/hydraulic-hoses"}
                            title="Hydraulic hoses for all applications"
                            desc={"Hydraulic hoses safely transfer fluid through the system. Their flexibility means they require very little installation space. The HANSA-FLEX range includes elastomeric and thermoplastic hoses with pressure supports with textile or steel braids or various spiral-wound steel wire reinforcements. This allows them to transport fluids with pressures up to 500 bar. The hoses are used in numerous applications in mobile and stationary hydraulic systems."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgMetalHose} learnMoreLink={"/metal-hoses"}
                            title="Metal hoses for extreme conditions"
                            desc={"The materials used in metal hoses make them extremely resistant to corrosion and abrasion. Excellent temperature tolerance, vacuum resistance, and extremely low permeability make metal hoses the preferred choice for transporting solid, liquid, and gaseous substances under particularly demanding conditions. HANSA-FLEX offers comprehensive advice for selecting the best metal hose solution."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgBluePipe} learnMoreLink={"/Ptfe-hoses"}
                            title="PTFE hoses for chemically demanding applications"
                            desc={"HANSA-FLEX PTFE hoses are distinguished by their excellent chemical resistance to most media, high flexibility, and long service life. In the food and pharmaceutical industries, their flavor and odor neutrality, as well as bacteriological safety, make them ideal for transporting potentially problematic products. In shipbuilding or the aerospace industry, PTFE hoses safely transport fuel or cooling water."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgjetSpray} learnMoreLink={"/ext-high-pfm-hoses"}
                            title="Extremely high-pressure hoses for applications up to 3,200 bar"
                            desc={"HANSA-FLEX extremely high-pressure hoses are always the first choice in systems where high pressures prevail. Indeed, these may have to withstand pressures up to 4,000 bar in the oil and gas industry, water jetting, hydraulic applications, and the automotive sector. HANSA-FLEX provides extremely high-pressure hoses specially adapted to your needs and supplied as ready-to-install hose lines."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgIndustrailHoses} learnMoreLink={"/Industrial-hoses"}
                            title="HANSA-FLEX is your system partner for industrial hoses"
                            desc={"Industrial hoses find application where the medium is not used to transmit energy. Industrial hoses transport a wide range of media, from water and air, through chemicals, to fuels and gases, in almost every industry sector. In its specialized hose workshop, HANSA-FLEX meets customer requirements with the highest quality standards and shortest delivery times. In the HANSA-FLEX online shop, you will find a wide range of industrial hoses."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgperforemdHoses} learnMoreLink={"/performed-hoses"}
                            title="HANSA-FLEX pre-formed hoses for high flexibility in tight spaces"
                            desc={"Pre-formed hoses are generally required when conventional hoses cannot be used due to limited installation space. For example, in situations where a hose transporting fuel or coolant in engines or batteries would have to be sharply bent, HANSA-FLEX pre-formed hoses provide an effective and economical solution. They are highly elastic, very robust, and can be manufactured in almost any shape."} /></Col>

                        <Col sm={12} md={6} lg={8} ><ImageCard image={imgaccessoresHoseLine} learnMoreLink={"/hose-accessories"}
                            title="Perfect protection for hoses with HANSA-FLEX accessories"
                            desc={"HANSA-FLEX hose lines convince in numerous applications due to their excellent quality. Products from the HANSA-FLEX accessories portfolio not only protect susceptible hose parts in situations where particularly high mechanical loads or effects of high temperatures occur, but also protect people, for example, from injuries caused by oil jets. A wide range of products and expert advice ensure the selection and implementation of effective and efficient protection solutions."} /></Col>
                    </Row>
                </Container>
            </div>

            <div>
                <Promoter
                    title={"DISCOVER NOW THE HANSA-FLEX HOSE LINE CONFIGURATOR"}
                    checklistItems={["Compact hoses for confined spaces for example, mobile hydraulic systems", "Thermoplastic hoses for particularly low weight"]}
                    btnLabel1={"Configure now"}
                    btnLabel2={"Learn more"}
                    btnLabel1Link={"https://shop.hansa-flex.be/nl_NL/"}
                    btnLabel2Link={"/hose-line-config"}
                    imageUrl={imgTabletMobile}
                />
            </div>

            <div>
                <ImageText
                    data={dataPipeFittings} />
            </div>

            {/* TODO: TextPoster */}

            <div>
                <SectionHeader
                    title={"SERVICES FOR HOSE LINES"}
                    subtitle={"HANSA-FLEX is a system partner that not only provides high-quality hoses and associated products, but also supports customers with numerous services for all tasks related to hoses."}
                />

                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"PRODUCTS FOR APPLICATIONS IN HOSE LINES"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"PRODUCT DEVELOPMENTS BY HANSA-FLEX"}
                    subtitle={"We know what our customers want and develop the perfect solutions."}
                />

                <ImageText
                    data={dataProductDevelopment1} />

                <ImageText
                    data={dataProductDevelopment2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"OTHER SERVICES FROM HANSA-FLEX FLUID SPECIALISTS"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imghosekit}
                                title="Hose kits for efficient installation"
                                desc={"As a systems partner, HANSA-FLEX provides ready-to-install kits containing components from third-party suppliers as well as HANSA-FLEX. Production lines ensure uninterrupted component supply. Our customers save time, increase efficiency, and reduce failure rates."}
                                learnMoreLink={"/Kitting"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgModernSeries}
                                title="Modern series production for OEM component suppliers"
                                desc={"The central production unit of HANSAFLEX in Bremen manufactures hose lines in large and small batches. The range includes high-pressure, PTFE, and industrial hoses in"}
                                learnMoreLink={"/SeriesProduction"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCustomisedHose}
                                title="Custom hose pre-assembly anywhere in the world."
                                desc={"The Hydraulic Service Container is a mobile hydraulic workshop for manufacturing hoses directly on-site, saving time and minimizing costly downtimes."}
                                learnMoreLink={"/Hydraulicworkshopcontainers"} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>

    );
}

export default HosesAndHoseLines;