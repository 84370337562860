import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';

import Stage from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Stage.jpg'
import Repair from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Repair.jpg'
import imgExcellentRange from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/ExcellentRange.jpg'
import imgEfficientOrder from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/EfficientOrder.jpg'
import Repair2 from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Repair2.jpg'
import Cylinder from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Cylinder.jpg'
import Manufacture from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Manufacture.jpg'
import Seals from '../../../assets/Services/RepairAndOverHaul/HydraulicCylinderRepair/Seals.jpg'
import imgMark from '../../../assets/mark.png'




const HydraulicCylinderRepair = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);


    const ProfessionalRepair = {
        image: Repair,
        title: 'Professional repair of defective or damaged cylinders',
        description: 'Our experienced experts quickly and reliably complete all the tasks involved in the professional repair of cylinders up to 10,000 mm long and 1,000 mm in diameter.',
        listItems: [
            "Honing at extra cost up to 6,000 mm length and approx. 500 mm diameter",
            "Rechroming piston rods",
            "Turning and milling seal housings",
            "Seals manufactured to dimensional requirements"]
    }

    const ExcellentRange = {
        image: imgExcellentRange,
        title: 'Excellent range of machinery and equipment ',
        description: 'The HANSA-FLEX cylinder repair centre has a range of modern machinery and equipment for cylinder repairs. Sandblasting and special paint coatings are a matter of course for us.',
        listItems: [
            "Conventional and CNC-controlled lathes",
            "Mounting holes for vertical installation and removal of cylinders",
            "Test bed in accordance with DIN 10100 for comprehensive functional tests",
            "Horning machine for overhauling damaged cylinder tubes"]
    }


    const EfficientOrder = {
        image: imgEfficientOrder,
        title: 'Efficient order processing',
        description: 'We check the condition of each cylinder before we decide whether the piston rod needs only to be lapped and polished or whether a new cylinder is required. Then the two neighbouring production areas, cylinder repair and sealing technology, work together in a well-practised routine to complete the maintenance of the cylinder, including the fitting of new seals.',
        listItems: [
            "Binding cost and services offer",
            "Inspection of damage cylinders can also be done at your premises",
            "Standard and special seals can be obtained quickly"]
    }



    const featuresData = [
        {
            title: "All-round service",
            description: "Dismantle, pick-up, repair, return and install",
            image: imgMark
        },
        {
            title: "Express service",
            description: "Repair within three days, brush electroplating for urgent repairs",
            image: imgMark
        },
        {
            title: "Comprehensive advice",
            description: "Professional repair, identification of optimisation potential",
            image: imgMark
        },
        {
            title: "Proven quality",
            description: "Performance of various tests and preparation of detailed test reports if required",
            image: imgMark
        }
        // Add more objects for additional FeaturesCard data
    ];

    const data1 = {
        image: Repair2,
        description: "Our experts have many years of experience, extensive practical know-how and are completely familiar with the specific requirements of the various cylinder types.",
        listItems: [
            "Plunger and telescopic cylinders",
            "Press, crane and casting cylinders",
            "Fire fighting, water engineering, gas metering and closing cylinders",
            "Mobile hydraulic and pneumatic cylinders",
            "Standard and special cylinders"
        ]
    }


    const isMobile = window.innerWidth <= 768;


    return (
        <div>

            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={'REPAIRING A CYLINDER IS A GOOD ALTERNATIVE TO BUYING A NEW ONE'}
                    paragraph={'Hydraulic cylinders are subject to extreme stresses due to their continual extending and retracting. The result is wear, which puts the whole working process at risk of failure. Overhauling a cylinder is a good alternative to buying a new one. The HANSA-FLEX cylinder repair centre repairs hydraulic cylinders of all sizes and manufacturer, hydraulic pumps, valves, power units and motors rapidly and with minimum downtime'}
                />

            </div>

            <SectionHeader
                title={'EFFICIENT CYLINDER REPAIR BY EXPERTS'}
                subtitle={''}
            />


            <div className='div-gray'>


                <div >
                    <br />
                    <ImageText data={ProfessionalRepair} left={false} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={ExcellentRange} /> <br /><br />
                </div>

                <div >
                    <br />
                    <ImageText data={EfficientOrder} left={false} /> <br /><br />
                </div>

            </div>


            <div
                className='div-gray center-all-items'>
                <SectionHeader
                    title={"A HANSA-FLEX CYLINDER REPAIR COMES WITH MANY BENEFITS"}
                    subtitle={"We offer a quick and professional repair by experienced experts with comprehensive service, all from the same provider. You benefit from minimum standstill times; we benefit from high customer satisfaction."}
                />
                <FeatureCardCarousel featuresData={featuresData} />

            </div>

            <div>
                <SectionHeader
                    title={"REPAIR OF CYLINDERS OF ALL TYPES AND MANUFACTURE"}
                    paragraph={"Our experienced hydraulics technicians repair over 6,000 hydraulic cylinders of all sizes, manufacturers and types every year."}
                />
                <ImageText
                    data={data1} />

            </div>

            <div>
                <SectionHeader
                    title={"PROFESSIONAL REPAIRS BY HANSA-FLEX"}
                    subtitle={"With HANSA-FLEX you benefit from rapid and professional repairs performed by experts experienced in fluid technology."}
                />
            </div>

            <div>
                <Container className='custom-container'>
                    <Row>
                        <Col sm={12} md={6} lg={4} ><ImageCard image={Cylinder}
                            title="Cylinder installation by professionals"
                            desc={"The HANSA-FLEX specialists repair damaged hydraulic cylinders of all sizes, manufacturers and types."}
                            buttonName="READ MORE" /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={Manufacture}
                            title="Manufacturing new cylinders"
                            desc={"The fluid experts from HANSA-FLEX are officially authorised persons for pressure equipment and support you with all the required maintenance tasks."}
                            buttonName="READ MORE" /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={Seals}
                            title="Seals made to measure"
                            desc={"The HANSA-FLEX cylinder repair centre not only reconditions hydraulic cylinders but also repairs other hydraulic components quickly to ensure minimum downtime."}
                            buttonName="READ MORE" /></Col>
                    </Row>
                </Container>

            </div>


        </div>








    )
}

export default HydraulicCylinderRepair
