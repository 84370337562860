import React from 'react';
import './TextCaption.css';
import { useTranslation } from 'react-i18next';
const TextCaption = ({ title, subtitle, paragraph, bulletPoints, numbered, version = 0 }) => {
  const { t } = useTranslation();
  var paddingTop = "4rem";
  var marginleft = "15%";

  if (version === 2) {
    paddingTop = "1rem";
    marginleft = "10%";
  }

  return (
    <div className="text-caption-header" style={{ marginleft: marginleft }}>
      {title && <h1 className="text-caption-title" style={{ color: '#28588F', paddingTop: paddingTop }}>{t(title)}</h1>}
      {subtitle && <p className="text-caption-subtitle">{t(subtitle)}</p>}
      {paragraph && <p className="text-caption-paragraph">{t(paragraph)}</p>}
      {bulletPoints && (
        <ul className={`bullet-point-list text-caption-bullets ${numbered ? 'numbered' : 'bullet'}`}>
          {bulletPoints.map((point, index) => (
            <li key={index}>{/* Using the Circle icon from React Icons */}
              {point}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TextCaption;
