import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import ImageText from '../../Components/ImageText/ImageText'
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import HFTabs from '../../Components/HfTabs/HfTabs'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../Components/ImageCard/ImageCard'
import imgMark from '../../assets/mark.png'


import imgStage from '../../assets/Products/Pneumatics/Stage.jpg'
import EfficientConnection from '../../assets/Products/Pneumatics/Efficient Connection.jpg'
import COmpressedAir from '../../assets/Products/Pneumatics/COmpressedAir.jpg'
import GeneratingAndTreating from '../../assets/Products/Pneumatics/GeneratingAndTreating.jpg'
import Cylinder from '../../assets/Products/Pneumatics/Cylinder.jpg'
import HosesAndPipes from '../../assets/Products/Pneumatics/HosesAndPipes.jpg'
import VaccumTech from '../../assets/Products/Pneumatics/VaccumTech.jpg'
import AirBlastGun from '../../assets/Products/Pneumatics/AirBlastGun.jpg'
import MaintenanceDevices from '../../assets/Products/Pneumatics/MaintenanceDevices.jpg'
import OperatingPneumatics from '../../assets/Products/Pneumatics/OperatingPneumatics.jpg'
import EnergyEfficiency from '../../assets/Products/Pneumatics/EnergyEfficiency.jpg'
import Productivity from '../../assets/Products/Pneumatics/Productivity.jpg'
import OccupationalSafety from '../../assets/Products/Pneumatics/OccupationalSafety.jpg'
import SuperbCleaning from '../../assets/Products/Pneumatics/SuperbCleaning.jpg'
import PremiumCompressedAir from '../../assets/Products/Pneumatics/PremiumCompressedAir.jpg'
import NonInterchnageable from '../../assets/Products/Pneumatics/NonInterchnageable.jpg'
import Install from '../../assets/Products/Pneumatics/Install.jpg'
import VaccumTech2 from '../../assets/Products/Pneumatics/VaccumTech2.jpg'
import Tracing from '../../assets/Products/Pneumatics/Tracing.jpg'



const Pneumatics = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const Data1 = {
        image: EfficientConnection,
        title: 'Efficient connections for pneumatic systems',
        listItems: ["Large selection of quick connectors", "Ball valves with pneumatic rotary actuators", "Thread and elastomer seal couplings and connectors", "Available in various materials"],
    }

    const Data2 = {
        image: COmpressedAir,
        title: 'Compressed air optimally distributed',
        listItems: ["Compressor, brake and flame-retardant hose", "Freely configurable pipeline and compressed air line systems", "Electric, pneumatic or manually controlled directional control valves", "NAMUR-compliant valves", "Solenoid valves and valve terminals"],
    }

    const Data3 = {
        image: GeneratingAndTreating,
        title: 'Generating and treating compressed air',
        listItems: ["Modular maintenance units in accordance with the Machinery Directive", "Wide range of filters with water separators", "Gauges with clogging indicator", "Compressed air preparation components, pressure regulators and compressors"],
    }

    const Data4 = {
        image: OperatingPneumatics,
        listItems: ["Connection elements in accordance with DVGW", "Compliance with US-American OSHA standards", "Products in accordance with the Machinery and Pressure Equipment Directives", "Fulfilment of the German Industrial Health and Safety Act (BetrSichV) and Workplace Ordinance (ArbStättV)", "Work carried out by experience fluid specialists"],
    }

    const Data5 = {
        image: SuperbCleaning,
        title: 'Superb cleaning performance at up to 10 bar with the Typhoon blowgun',
        listItems: ["Flow-optimised design and multi-channel nozzle", "Infinitely adjustable cleaning performance", "Excellent handling due to the ergonomically shaped rubberised grip"],
        buttonName: "ORDER NOW"
    }

    const Data6 = {
        image: PremiumCompressedAir,
        title: 'Premium compressed air couplings for enhanced safety',
        listItems: ["High flow rate of 1,600 l/min with low air and energy consumption", "Easy, safe and intuitive coupling using one hand", "3-part seal for significantly fewer leaks"],
        buttonName: "PURCHASE PRODUCTS"
    }

    const Data7 = {
        image: NonInterchnageable,
        title: 'Non-interchangeable couplings for greater operational safety',
        listItems: ["The same colour markings indicate interchangeable coupling halves", "The halves must have the same shape to be successfully coupled", "Available in different colours"],
        buttonName: "PRODUCTS"
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Reliability in use",
            description: "The HANSA-FLEX pneumatic portfolio meets the highest standards and is subject to comprehensive testing.",
            image: imgMark
        },
        {
            title: "Designed for efficiency",
            description: "Our pneumatic components are designed for maximum efficiency and optimised for high flow rates.",
            image: imgMark
        },
        {
            title: "No compromise on safety",
            description: "We supply safety-related products such as hose rupture valves and safety couplings.",
            image: imgMark
        },
        {
            title: "Fulfils all common standards",
            description: "For example: compliance with the Machinery, Pressure Equipment and ATEX Directives, and certification in accordance with RoHS.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Pneumatic cylinders",
            listItems: ["Pneumatic cylinders in accordance with ISO 6432, ISO 15552 and ISO 21287", "Round, short-stroke, standard and compact cylinders", "Rodless cylinders"],
            buttonName: "ALL CYLINDERS",
            image: Cylinder
        },
        {
            title: "Pneumatic hoses and pipes",
            listItems: ["Compressor and brake hoses", "PA, PE, PUR, PVDF and PVC hoses", "Pipelines, flanges, fastenings and connection"],
            buttonName: "PRODUCTS",
            image: HosesAndPipes
        },
        {
            title: "Vacuum technology for generating vacuums",
            listItems: ["Vacuum ejectors including suitable accessories", "Switches, flow valves and vacuum sensors", "Bellows and flat suction pads"],
            buttonName: "SHOP NOW",
            image: VaccumTech
        },
        {
            title: "Energy-saving blowguns",
            listItems: ["Blowguns manufactured from plastic and diecast aluminium", "Nozzles and safety nozzles", "Accessories and spare parts"],
            buttonName: "MORE ABOUT BLOWGUNS",
            image: AirBlastGun
        },
        {
            title: "Pressure regulators, filters and maintenance units",
            listItems: ["Maintenance units and combined maintenance devices", "Various pressure regulators, special filters and proportional valves", "Oil-water separators and leak detectors"],
            buttonName: "ALL MAINTENANCE DEVICES",
            image: MaintenanceDevices
        },
    ];

    const tabTitles = [
        { title: "Cylinders", eventKey: "Cylinders" },
        { title: "Hoses and pipes", eventKey: "Hoses and pipes" },
        { title: "Vacuum technology", eventKey: "Vacuum technology" },
        { title: "Air blast guns", eventKey: "Air blast guns" },
        { title: "Maintenance devices", eventKey: "Maintenance devices" },
    ];

    const imageTextData2 = [
        {
            title: "Save energy and protect the environment",
            listItems: ["Energy optimisation for pneumatic systems", "Tracing and sealing leaks", "Reducing system pressure to relieve the forces on components"],
            image: EnergyEfficiency
        },
        {
            title: "Cleaning compressed air and ensuring system availa",
            listItems: ["Modular maintenance units in accordance with the Machinery Directive", "Pressure and temperature measurement (digital and analogue)", "Oil-water separators and filters"],
            image: Productivity
        },
        {
            title: "Prevent accidents and keep employees safe",
            listItems: ["Hose rupture protection to prevent uncontrolled whiplash", "Brightly coloured hoses reduce the risk of tripping", "Low-noise blowguns and nozzles for noise reduction", "Safety couplings prevent whiplash [Ankerlink zu 5. “Featured products > Safety couplings”]", "Non-interchangeable couplings eliminate connection mix-ups [Ankerlink zu 5. “Featured products > Non-interchangeable couplings”]"],
            image: OccupationalSafety
        },
    ];

    const tabTitles2 = [
        { title: "Energy efficiency", eventKey: "Energy efficiency" },
        { title: "Productivity", eventKey: "Productivity" },
        { title: "Occupational safety", eventKey: "Occupational safety" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"USING PNEUMATICS TO MOVE, CONTROL AND POSITION"}
                    paragraph={"Pneumatics are at the heart of automated processes in logistics centres, small parts warehouses and the automotive industry. Compressed air is also used in vacuum technology and is indispensable for digital lifting and positioning operations. The quality and design of the components used make a crucial difference to the productivity and efficiency of pneumatic systems. HANSA-FLEX offers effective solutions and customised services."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"COMPRESSED AIR TECHNOLOGY FROM HANSA-FLEX"}
                    subtitle={"The HANSA-FLEX range of compressed air equipment includes over 18,000 high-quality pneumatic components such as cylinders, control valves, tools and accessories suitable for all industries and the most challenging operating environments."}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
                <ImageText
                    data={Data3} />
            </div>

            <div>
                <SectionHeader
                    title={"YOU CANNOT GO WRONG WITH HANSA-FLEX PNEUMATIC COMPONENTS"}
                    subtitle={"When moving compressed air, every detail matters. A space-saving installation is just as important as efficient and cost-optimised operation."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"COMPRESSED AIR TECHNOLOGY FROM HANSA-FLEX IN THE ONLINE SHOP"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"OPERATING PNEUMATIC SYSTEMS SAFELY"}
                    subtitle={"With tried and tested components from HANSA-FLEX, you are always on the safe side. Our team of highly experienced experts helps you maintain and operate your pneumatic system."}
                />
                <ImageText
                    data={Data4} />
            </div>

            <div>
                <SectionHeader
                    title={"THE PERFECT FILTER SOLUTION FOR ALL HYDRAULICS APPLICATIONS"}
                />
                <HFTabs imageTextData={imageTextData2} tabTitles={tabTitles2} />
            </div>

            <div>
                <SectionHeader
                    title={"YOUR FULL-SERVICE PARTNER FOR EVERYTHING TO DO WITH PNEUMATIC SYSTEMS"}
                    subtitle={"Our experts have extensive experience in all fields of compressed air technology. From planning and design to installation and ongoing operation, we support you in all the tasks required on your pneumatic system and are always available to answer questions on any topic, including energy efficiency."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"SELECTED PRODUCTS FROM THE HANSA-FLEX COMPRESSED AIR TECHNOLOGY RANGE"}
                />
                <ImageText
                    data={Data5} />
                <ImageText
                    data={Data6}
                    left={false} />
                <ImageText
                    data={Data7} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={Install}
                                title="Installation and pipework"
                                desc={"HANSA-FLEX Industrial Service installs pneumatic systems including pipework and connections between all components."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={VaccumTech2}
                                title="Vacuum technology for positioning tasks"
                                desc={"The HANSA-FLEX online shop has a large selection of suction elements, control units and sensors as well as equipment for local vacuum creation using in-line ejectors."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Tracing}
                                title="Tracing the hiss"
                                desc={"The experts at HANSA-FLEX advise and assist you with modernising and optimising your pneumatic systems, for example when you need to trace and seal leaks."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )
}


export default Pneumatics